@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color-text1: #363636;
    --color-text2: #222428;

    --color-primary: #fdcd59;
    --color-primary-light: #FFE5A7;
    --color-primary-hover: #e0ab23;
    --color-secondary: #f5f3f0;
    --color-line: #e6e6e6;

    --color-input: #3e4249;

    --color-card: #fdcd5980;

    font-size: 18px;
    font-family: "Karla", sans-serif;
}

.dark {
    --color-text1: #f5f3f0;
    --color-text2: #ffffff;

    --color-primary: #363636;
    --color-secondary: #222428;

    --color-card: #fdcd5980;

    --color-line: #e6e6e6;
}

body {
    @apply text-white;
    font-family: "VT323", monospace;
}
